import React, {useEffect} from "react";
import ContactForm from "../Components/ContactForm";
import Glider from "../Components/Glider";
import {Link} from "react-router-dom";

const Home = () => {

    const [slider, setSlider] = React.useState(null);
    
    useEffect(() => {
        setSlider([
            {image:"slide_1.jpg",alt:"Windermere at Cartmell"},
            {image:"slide_2.jpg",alt:"Cartmell Lakes"},
            {image:"slide_3.jpg",alt:"Windermere Main Lobby"},
            {image:"slide_4.jpg",alt:"Windermere Library"},
            {image:"slide_5.jpg",alt:"Windermere Ice Cream Parlour"},
            {image:"slide_6.jpg",alt:"Windermere Beauty Salon"},
            {image:"slide_7.jpg",alt:"Windermere Gym"},
            {image:"slide_8.jpg",alt:"Windermere Pool Tabel"},
            {image:"slide_9.jpg",alt:"Sir Alfreds Man Cave"},
            {image:"slide_10.jpg",alt:"Windermere Mimosa Room"},
            {image:"slide_11.jpg",alt:"Windermere Dining Hall"},
            {image:"slide_12.jpg",alt:"Cartmell Communities"}
           
        ])
    },[])
    return (
        <React.Fragment>
            <div className="bg-cartmell-beige">
            {(slider !== null) ? (
                <Glider data={slider}/>
            ) : ("")}
          
            <section className="container my-5 2xl:w-2/3 mx-auto">
                <div className="flex flex-col space-y-8 lg:space-y-0 lg:flex-row justify-between px-5 lg:px-1">
                    <div
                        className="relative w-full lg:w-7/24 relative drop-shadow-md transform hover:scale-105 hover:drop-shadow-lg transition-transform duration-500">
                        <img className="rounded-lg ring-8 ring-cartmell-white" src="../dist/img/CL-sign.webp"
                             alt="Cartmell Lakes"/>
                        <div
                            className="absolute bg-cartmell-red/75 text-cartmell-white  w-full h-full top-0 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-500">
                            <Link className="w-full h-full flex flex-col justify-center items-center cartmell-lake-link"
                               to="/cartmell-lakes">
                                
                                <h2 className="font-charm text-4xl">Cartmell Lakes</h2>
                                <p>A Gated Senior Living Community</p>
                            </Link>
                        </div>
                            <div className="w-100 absolute top-0 text-white text-4xl font-bold" style={{paddingLeft: "1rem", opacity:".75",pointerEvents:"none"}} >
                                
                                <p>Now Open</p>
                            </div>
                    </div>
                    <div
                        className="w-full lg:w-7/24 relative drop-shadow-md transform hover:scale-105 hover:drop-shadow-lg transition-transform duration-500">
                        <img className="rounded-lg ring-8 ring-cartmell-white" src="../dist/img/slide_1.jpg"
                             alt="Windermere at Cartmell"/>
                        <div
                            className="absolute bg-cartmell-red/75 text-cartmell-white w-full h-full top-0 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-500">
                            <Link className="w-full h-full flex flex-col justify-center items-center"
                               to="/windermere">
                                <h2 className="font-charm text-4xl">Windermere at Cartmell</h2>
                                <h1>An Assisted Living Community</h1>
                            </Link>
                        </div>
                    </div>
                    <div
                        className="w-full lg:w-7/24 relative drop-shadow-md transform hover:scale-105 hover:drop-shadow-lg transition-transform duration-500">
                        <img className="rounded-lg ring-8 ring-cartmell-white" src="../dist/img/slide_2.jpg"
                             alt="Reflections at Windermere"/>
                        <div
                            className="absolute bg-cartmell-red/75 text-cartmell-white w-full h-full top-0 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-500">
                            <Link className="w-full h-full flex flex-col justify-center items-center"
                               to="/reflections">
                                <h2 className="font-charm text-3xl">Reflections at Windermere</h2>
                                <h1>A Memory Care Living Community</h1>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="flex flex-row bg-cartmell-red justify-center p-10 drop-shadow-md">
                    <h3 className="font-montecarlo text-center text-2xl md:text-4xl lg:text-5xl 2xl:text-8xl text-cartmell-white">Where
                        dignity, love and professionalism are a way of life</h3>
                </div>
            </section>
                <section className="flex flex-row justify-center py-5">
                    <div className="mx-5 2xl:w-2/3 p-5" style={{maxWidth: "800px"}}>
                        <p className="text-center text-xl">Cartmell Communities is a not for profit, charitable
                            organization
                            chartered in 1953, as directed in the Will of <Link className="text-cartmell-red underline"
                                                                                to="/miss-cartmell">Miss Sarah
                                E. J. Cartmell.</Link> Miss Cartmell was originally born in England and lived in
                            Palestine, Texas since her early childhood. Cartmell began serving the Palestine Community in 1956.
                            Cartmell Lakes is a 46-acre gated senior independent living community. Windermere at Cartmell is a custom
                            designed, state of the art assisted living facility, offering
                            both Assisted Living and Memory Care services in two distinct neighborhoods. Under the
                            guidance of a dedicated local volunteer board of directors and professional staff, Cartmell has
                            gained recognition as one of the finest senior care communities in Texas.</p>
                    </div>
            </section>
                <section className="bg-cartmell-white w-full flex justify-center py-5">
                    <div>
                        <ContactForm  />
                        
                    </div>
                </section>
            </div>
        </React.Fragment>
    );
}

export default Home;