import React, {useEffect, useState} from "react";
import SloganSection from "../Components/SloganSection";
import {useDispatch, useSelector} from "react-redux";
import $ from "jquery";
import {setImage} from "../../Redux/slice/Modal";
import InfoSection from "../Components/InfoSection";

const Reflections = () => {
    
    const [unitInfo, setUnitInfo] = useState(0);
    const shouldShow = useSelector((state) => state.shouldShowContents.value);
    const dispatchModal = useDispatch();
    
    const unitData = [
        {
            name: "Reflections at Windermere",
            images: ["/dist/img/reflections1.jpg","/dist/img/reflections2.jpg"]
        },
        {
            name: "Reminisce Room",
            images: ["/dist/img/reminisce1.jpg","/dist/img/reminisce2.jpg"]
        },
        {
            name: "Peaceful Sitting Areas",
            images: ["/dist/img/sitting1.jpg","/dist/img/sitting2.jpg"]
        },
        {
            name: "Arts & Crafts",
            images: ["/dist/img/art1.jpg","/dist/img/art2.jpg"]
        },
        {
            name: "Homestyle Dining",
            images: ["/dist/img/dining1.jpg","/dist/img/dining2.jpg"]
        },
        {
            name: "Interior Courtyards & Walking Paths",
            images: ["/dist/img/walk1.jpg","/dist/img/walk2.jpg"]
        },
        {
            name: "Handicap Accesible Private Bathrooms",
            images: ["/dist/img/bathroom1.jpg"]
        },
        {
            name: "Inhouse Beauty Salon",
            images: ["/dist/img/salon1.jpg"]
        },
       
        {
            name: "Outdoor Gardening Activities",
            images: ["/dist/img/garden1.jpg","/dist/img/garden2.jpg","/dist/img/garden3.jpg"]
        }
    ]

    useEffect(() => {
        
        const container = $('.topContainer').children() ;
        
        container.each((index, child) => {
            
            if (index === parseInt(unitInfo)) {
                $(container[index]).addClass("active");
            } else {
                $(container[index]).removeClass("active");
            }
        })
    }, [unitInfo])

    const changeTab = (e) => {
        
        const element = e.target;
        setUnitInfo(element.id.split("-")[1]);
    };

    const displayModal = (image) => {
   
        dispatchModal(setImage(image));
    }

    return (
        <React.Fragment>
            <section className="flex justify-center bg-cartmell-white " style={{
                width: "100w",
                height: "50vw",
                maxHeight: "650px",
                overflow: "hidden",
                position: "relative"
            }}>
                <img style={{objectFit: "cover", position: "absolute"}} src="/dist/img/slide_2-large.webp"
                     alt="Reflections at Windermere" className="hero-image"/>
            </section>
            <SloganSection/>
            <section className="flex flex-row justify-center bg-cartmell-white p-5">
                <div className="m-5 py-5 lg:w-2/3">
                    <h1 className="text-center p-3 font-charm font-bold text-cartmell-red text-4xl">"Reflections" at Windermere</h1>
                    <p className="text-center text-xl">Included in the Windermere community is Reflections at
                        Windermere, a specialized Memory Care neighborhood designed for individuals whose cognition is
                        compromised and requires a secure environment where they can receive assistance with their
                        activities of daily living. The Reflections staff has been specially trained to help each
                        resident experience pleasant days while also maintaining their dignity and individuality. A
                        dedicated Activity Director will personalize programs designed to engage these residents. This
                        neighborhood has parking and a private entrance available to the family and friends of those
                        living in Reflections at Windermere. </p>

                </div>
            </section>
            <section className="flex flex-row justify-center py-5 max-container-width bg-cartmell-white  ">

                <div className="flex flex-col w-full " >
                    <div id="top-tabs" className="hidden md:flex h-100" style={{display: "flex",flexDirection: "column"}}>
                        
                        
                        {(!shouldShow ) ? (
                                <>
                                <div id="top-tabs__container" className="topContainer height100">
                                    {unitData.map((element, index) => {
                                        if (index > 4) return null;
                                        return (
                                            <div key={index} id={"tab-" + index} className={"top-tabs__btn height100"}
                                                 onClick={changeTab}>{element.name}
                                            </div>
                                        )
                                    })}
        
                                </div>
                                <div id="top-tabs__container" className="topContainer height100" style={{borderTop: "2px solid rgb(90 12 45) "}}>
                                    {unitData.map((element, index) => {
                                        if (index < 5) return null;
                                        return (
                                            <div key={index} id={"tab-" + index} className={"top-tabs__btn height100"}
                                                 onClick={changeTab}>{element.name}
                                            </div>
                                        )
                                    })}
        
                                </div>
                                </>
                            ) : (<></>)}
                       
                      
                    </div>
                  
                    <div
                        className="  w-full flex flex-col md:flex-row bg-cartmell-red md:rounded-b-xl text-cartmell-white p-5 shadow-md"
                        id="tabs-div__container">
                        {unitData.map((element, index) => {
                            
                            if (parseInt(unitInfo) === index || shouldShow) {
                                return (
                                    <>
                                    <h1 className="md:hidden text-center font-charm font-bold text-cartmell-white text-2xl pt-5">{element.name}</h1>
                                        {element.images.map((image, index) => {
                                        return (
                                            <>
                                                <div key={"container-" + index}
                                                     className="tabs-div__container image-singles ">
                                                    <img key={"container-image-" + index}
                                                         src={image}
                                                         alt=""
                                                         className="   drop-shadow-xl cursor-pointer transform hover:scale-105 hover:drop-shadow-lg transition duration-500"
                                                         id="floorImg1"
                                                         onClick={() => displayModal(image)}/>
                                                </div>
                                            </>
                                        )
                                    }
                            )}
                                 </>   
                            )}
                        })}
                    </div>
                </div>
            </section>
            <InfoSection />
        </React.Fragment>
    );
}

export default Reflections;