import React, {useEffect, useState} from "react";
import ContactForm from "../Components/ContactForm";
import TabbedViewer from "../Components/TabbedViewer";
import Glider from "../Components/Glider";
import SloganSection from "../Components/SloganSection";
import {setImage} from "../../Redux/slice/Modal";
import {useDispatch, useSelector} from "react-redux";
import $ from "jquery";
import Map from "../Components/Map";
import InfoSection from "../Components/InfoSection";


const Windermere = () => {

    const [slider, setSlider] =useState(null);
    const [unitInfo, setUnitInfo] = useState(0);
    const [imageToShow, setImageToShow] = React.useState(1);
    const dispatchModal = useDispatch();

    const winPlanTypes = ["Exterior View", "Courtyard Access", "Window Seat"];
    const winPlanSize = ["Studio","One Bedroom","Two Bedroom"];
    const winPlans = [
        {
            size: 0,
            types:[
                {
                    model:0,
                    image: "../../dist/img/windermere-floor1.jpg"
                },
                {
                    model:1,
                    image: "../../dist/img/windermere-floor2.jpg"
                }
            ],
        },
        {
            size: 1,
            types:[
                {
                    model:2,
                    image: "../../dist/img/windermere-floor3.jpg"
                },
                {
                    model:1,
                    image: "../../dist/img/windermere-floor4.jpg"
                }
            ],
        },
        {
            size: 2,
            types:[
                {
                    model:2,
                    image: "../../dist/img/windermere-floor5.jpg"
                },
                {
                    model:1,
                    image: "../../dist/img/windermere-floor6.jpg"
                }
            ],
        }
    ]
    
    useEffect(() => {
        
        const numberOfImages = 19;
        const imagesObject = [];
        
        for(let i = 1; i <= numberOfImages; i++) {
            imagesObject.push( {image:"Windermere_FlipBook_New_" +i+ ".jpg",alt:""});
        }
        
        setSlider(imagesObject);
        
    },[])

    const changeTab = (e) => {
        
        const element = e.target;

        setUnitInfo(element.id.split("-")[1]);

    };
    const changeSubTab = (e) => {
        
        const element = e.target;

        if (element === undefined) return

        checkSubTabs(parseInt(element.id.split("-")[1]))

    };

    const displayModal = (image) => {
       
        dispatchModal(setImage(image));

    }

    useEffect(() => {

        const container = $('#top-tabs__container').children();

        container.each((index) => {

            if (index === parseInt(unitInfo)) {
                $(container[index]).addClass("active");
                checkSubTabs();
            } else {
                $(container[index]).removeClass("active");
            }
        })
    }, [unitInfo])
    
  

    const checkSubTabs = (numberSet = 0) => {
      
        const children = $('#top-tabs').siblings()[0].children;
        
      
        $(children).each((index, child) => {
            if(index === numberSet ) {
                $(child).addClass("active");
            }else{
                $(child).removeClass("active");
            }
        })
        const sizes = parseInt(unitInfo)
        setImageToShow((sizes > 0 ? sizes * 2  : sizes) + numberSet + 1 )
      
    }

    return (
        <React.Fragment>
            <div className="bg-cartmell-beige relative ">


                <section className="flex justify-center" style={{
                    width: "100w",
                    height: "50vw",
                    maxHeight: "650px",
                    overflow: "hidden",
                    position: "relative"
                }}>
                    <img style={{objectFit: "cover", position: "absolute", bottom: "0"}} src="/dist/img/slide_1.jpg"
                         alt="Windermere at Cartmell"/>
                </section>
                <SloganSection/>
                <section className="flex flex-row justify-center bg-cartmell-white p-5">
                    <div className="m-5 py-5 lg:w-2/3">
                        <p className="text-center text-xl">If convenience, comfort and quality are at the top of your
                            list of must-haves, you’ll find them in abundance at Windermere at Cartmell, our new 48,000
                            square foot community. Windermere provides a lifestyle of choices, with care options that
                            can be customized to meet your needs as they change in our Assisted Living and Memory Care
                            neighborhoods. This new custom designed state of the art community has 45 apartments
                            offering a variety of studio, one bedroom, and two bedroom floor plans.</p>
                    </div>
                </section>
                <div className="w-full bg-cartmell-beige">
                    <h2 className="font-montecarlo  text-center text-cartmell-red py-5 banner-text-control bigger">Take
                        A Virtual Tour of Windermere</h2>

                </div>
                <div className="w-full flex flex-row justify-center">

                    {(slider !== null) ? (
                        <Glider data={slider}/>
                    ) : ("")}

                </div>
            </div>
            <div className="w-full bg-cartmell-red gradient1">
                <h2 className="font-montecarlo  text-center text-cartmell-white pt-5 banner-text-control bigger">Windermere Floor Plans</h2>

            </div>
            <div className="w-full bg-cartmell-red gradient1 " >
                
                <section className=" flex flex-row justify-center max-container-width " style={{paddingBottom: "50px !important", zIndex: "50"}}>

                    <div className="flex flex-col w-full " style={{maxWidth: "600px", marginBottom: "50px", marginLeft: "1rem", marginRight: "1rem"}} >
                        <div id="top-tabs" className="show">
                            <div id="top-tabs__container">
                                {winPlans.map((element, index) => {
                                    return (
                                        <div key={index} id={"tab-" + index} className={"top-tabs__btn "}
                                             onClick={changeTab}>{winPlanSize[element.size]}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div id="top-tabs__container" style={{paddingTop: "2px"}}>
                            {winPlans[unitInfo].types.map((type, index) => {
                                return (
                                    <div key={index} id={"tab2-" + index} className={"top-tabs__btn bg-cartmell-beige"}
                                         onClick={changeSubTab}>{winPlanTypes[type.model]}
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div
                            className="flex flex-col md:flex-row bg-cartmell-beige-darker rounded-b-xl  text-cartmell-white p-5 shadow-md"
                            id="">
                            <img src={"../../dist/img/windermere-floor" + (parseInt(imageToShow)) + ".jpg"}
                                 alt="Apartment A"
                                 className="   drop-shadow-xl cursor-pointer transform  hover:drop-shadow-lg transition "
                                 id="floorImg1"
                                 onClick={() => displayModal("../../dist/img/windermere-floor" + (parseInt(imageToShow)) + ".jpg")}/>
                        </div>
                        
                    </div>
                </section>
            </div>
            <InfoSection />
        </React.Fragment>
    );
}

export default Windermere;