import React, {useEffect, useState} from "react";
import $ from 'jquery';
import {Link, useNavigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";


const Menu = () => {
    
    const [timer, setTimer] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const shouldShowAll = useSelector((state) => state.shouldShowContents.value);
    
    
    useEffect(()=>{
        if(timer === null || timer === 0) return;
        
        const timerId = setTimeout(()=>{
            
        })
    },[timer])
    
    useEffect(() => {
        
        const menuItems = $('nav > ul > li');
        const location =  window.location.pathname;
        
        menuItems.each((index,menuItem) => {
            const hasSubMenu = $(menuItem).children('div');
            
            if (hasSubMenu.length > 0) {
                let hasLink = false;
       
                hasSubMenu.children('a').each((_, subItem) => {
                    const result = checkLinkForActive(subItem);
                    if (result) {
                        hasLink = true;
                    }
            });
                if (hasLink) {
                    $(menuItem).addClass('menu-active');
                } else {
                    $(menuItem).removeClass('menu-active');
                }
                
                $(menuItem).on("mouseover",() => {
                    
                    $(menuItem).children('div').first().attr('style', '');
                });
                $(menuItem).on("mouseout",() => {
                    $(menuItem).children('div').first().attr('style', 'display: none');
                });
                
            }else{
                if(index !== menuItems.length - 1) {

                    checkLinkForActive(menuItem.firstChild);
                    // checkLinkForActive(menuItem);
                }
            }
        });
            $(menuItems).on('click', (e) => {
                
                const selection = $(e.target);
                if(selection.children('a').length <= 0) return;
                navigate(selection.children('a').attr('href'));
            })
    }, [location])

    const checkLinkForActive = (menuItem) => {
        const link = menuItem.href;
        if(link === undefined) return;
        // console.log("menuitem",link.split('/').pop(), menuItem)
        
        if (link.split('/').pop() === location.pathname.toString().split("/").pop() || link.split('/').pop() === "/") {

            
            $(menuItem).addClass('menu-active');
            return true;
        } else {

            $(menuItem).removeClass('menu-active');
            return false;
        }
    }
    
    
    const changeVisibility =(e) => {
        
        const menu = $('#menu');
        const selection = e.target;

        if (selection.closest("nav") === null) {
            menu.addClass('hidden');
        }
        if (selection.closest("#burger") !== null || (selection.closest("#menu") && !menu.hasClass('hidden'))) {
            console.log("burger")
            if (menu.hasClass('hidden')) {
                menu.removeClass('hidden');
                console.log("show")
            } else {
                console.log("hide")
                menu.addClass('hidden');

                if (selection.tagName === "LI") {
                    const child = selection.firstChild
                    child.click();
                    
                }
            }
        }
    }
    
    const handleContactScroll = (e) => {
      
        const contactForm = $('#contact');
        if(contactForm.length <= 0 ) return;
        
        contactForm[0].scrollIntoView({ block: "center", inline: "center", behavior: "smooth", alignToTop: false });
    }
    
    return (
        <React.Fragment>
            <div className="sticky absolute top-0 z-50" onClick={changeVisibility}>
                
                <nav
                    className="bg-cartmell-white text-cartmell-red font-bold lg:text-2xl flex flex-col lg:flex-row justify-center items-end shadow-md z-50">
                    <div className="cursor-pointer" id="burger" >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 m-3 text-cartmell-red lg:hidden"
                             viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd"
                                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                  clipRule="evenodd"/>
                        </svg>
                    </div>
                    <ul className="hidden "
                        id="menu">
                        <li  className="menuBtn ">
                            <Link  to="/">Home</Link>
                        </li>
                        <li  className="menuBtn  menuBtn__container relative"  
                        >Our
                            Communities
                            <div
                                className="  flex flex-col absolute mt-3 -ml-3 w-full bg-cartmell-white text-lg shadow-md"
                                style={{display: "none"}}>
                                <Link key={0}
                                      to="cartmell-lakes">Independent Living</Link>
                                <Link key={1}
                                      to="windermere">Assisted Living</Link>
                                <Link key={2}
                                   to="/reflections">Memory Care</Link>
                            </div>
                        </li>
                        <li  className=" menuBtn hiddable">
                            <Link to="/cartmell-lakes">Independent Living</Link>
                        </li>
                        <li  className=" menuBtn hiddable ">
                            <Link  to="/windermere">Assisted Living</Link>
                        </li>
                        <li  className=" menuBtn hiddable ">
                            <Link  to="/reflections">Memory Care</Link>
                        </li>
                        <li  className="menuBtn">
                            <Link  to="/aboutus" id="myVid">About Us</Link>
                        </li>
                        <li  className="menuBtn">
                            <Link to="/donations">Donations</Link>
                        </li>
                        <li   className="menuBtn">
                            <Link  onClick={(e)=> handleContactScroll(e)}>Contact</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </React.Fragment>
    );
}

export default Menu;