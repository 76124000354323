import React, {useEffect, useState} from "react";
import Glider from "../Components/Glider";
import $ from "jquery";
import {useDispatch, useSelector} from "react-redux";
import {setImage} from "../../Redux/slice/Modal";
import SloganSection from "../Components/SloganSection";
import InfoSection from "../Components/InfoSection";


const CartmellLakes = () => {

    const [slider, setSlider] =useState(null);
    const [unitInfo, setUnitInfo] = useState(0);
    const dispatchModal = useDispatch();
    const shouldShowAll = useSelector((state) => state.shouldShowContents.value);
    
    const unitData = [
        {
            name: "Apartment A",
            features: ["One Bedroom","One Bathroom", "Walk-in Closet","Patio","Storage Room","Washer & Dryer","Approximately 863 Sq Ft"]
        },
        {
            name: "Apartment B",
            features: ["One Bedroom","One Bathroom", "Walk-in Closet","Patio","Storage Room","Washer & Dryer","Approximately 890 Sq Ft"]
        },
        {
            name: "Apartment C",
            features: ["One Bedroom","One Bathroom", "Study/Den","Walk-in Closet","Patio","Storage Room","Washer & Dryer","Approximately 1040 Sq Ft"]
        },
        {
            name: "Apartment D",
            features: ["Two Bedroom","Two Bathroom", "Walk-in Closet","Patio","Storage Room","Washer & Dryer","Approximately 1265 Sq Ft"]
        },
        {
            name: "Cottage",
            features: ["One Bedroom","One Bathroom", "Walk-in Closet","Front & Back Porch","Storage Room","Utility Room","Garage","Approximately 988 Sq Ft"]
        },
        {
            name: "Garden Home",
            features: ["Two Bedroom","Two Bathroom", "Study/Den","Walk-in Closet","Front & Back Porch","Storage Room","Utility Room","Garage","Approximately 1447 Sq Ft"]
        }
    ]

    useEffect(() => {
        setSlider([
           
            {image:"CL-facility.jpg",alt:""},
            {image:"CL-1.jpg",alt:""},
            {image:"CL-2.jpg",alt:""},
            {image:"CL-3.jpg",alt:""},
            {image:"CL-4.jpg",alt:""},
            {image:"CL-5.jpg",alt:""},
            {image:"CL-6.jpg",alt:""},
            {image:"CL-7.jpg",alt:""},
            {image:"construction.jpg",alt:""},
            {image:"site-plan.jpg",alt:""},
            {image:"ground-breaking.jpg",alt:""}
           

        ])
    },[])
 
    
    useEffect(() => {
        
        // setMidPoint(Math.ceil(unitData[unitInfo].features.length / 2));
        const container = $('#top-tabs__container').children();
        
        container.each((index, child) => {

            if (index === parseInt(unitInfo)) {
                $(container[index]).addClass("active");
            } else {
                $(container[index]).removeClass("active");
            }
        })
    }, [unitInfo])
    
    const changeTab = (e) => {
        
        const element = e.target;
        setUnitInfo(element.id.split("-")[1]);
    };
    
    const displayModal = (image) => {
      
        dispatchModal(setImage(image));
    }
    
    
    return (
        <React.Fragment>
            <section className="flex justify-center bg-cartmell-white gradient" style={{
                width: "100w",
                height: "50vw",
                maxHeight: "650px",
                overflow: "hidden",
                position: "relative"
            }}>
                <img style={{objectFit: "cover", position: "absolute"}} src="/dist/img/CL-sign.webp"
                     alt="Reflections at Windermere" className="hero-image"/>
            </section>

            <SloganSection/>
            <section className="flex flex-row justify-center bg-cartmell-white  py-5">
                <div className="mx-5 lg:w-2/3 py-5" style={{maxWidth: "800px"}}>
                    <p className="text-center text-xl">Cartmell Lakes is now open. This new 46-acre community is nestled
                        in the Cartmell woods next to
                        Town Creek and South Loop 256. Cartmell Lakes is a maintenance free and socially
                        interactive living environment. Amenities include a clubhouse, fitness center, indoor pool,
                        craft room, table tennis, pool table, pickle ball court, bocce ball, putting green, walking
                        paths, daily
                        meals, coordinated activities and social events, and housekeeping services. Our Cottages and
                        Garden Homes offer a variety of floor plans ranging from 1,000 to 1,600 square feet,
                        including a two car garage to allow enough room for your vehicle and a golf cart. We also have a
                        limited supply of single story apartment units
                        with covered parking and a variety of floor plans to choose from.</p>
                </div>
            </section>
            <section className="bg-cartmell-beige gradient1" style={{paddingTop: "3rem", paddingBottom: "3rem"}}>
                {(slider !== null) ? (
                    <Glider data={slider}/>
                ) : ("")}
                
            </section>
            <section className="flex flex-row justify-center sm:py-5  bg-cartmell-white  ">

                <div className="flex flex-col w-full max-container-width ">
                    <div id="top-tabs">
                        <div id="top-tabs__container">
                            {unitData.map((element, index) => {
                                return (
                                    <div key={index} id={"tab-" + index} className={"top-tabs__btn "}
                                         onClick={changeTab}>{element.name}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div
                        className="flex flex-col md:flex-row bg-cartmell-red lg:rounded-b-xl  text-cartmell-white p-5 shadow-md"
                        id="tabs-div__container">
                        {unitData.map((element, index) => {

                            if (shouldShowAll || parseInt(unitInfo) === index) {
                                const mid = Math.ceil(unitData[index].features.length / 2)
                                return (
                                    <>

                                        <div key={"div1-" + index} className="tabs-div__container text ">
                                            <h1 className="text-left font-charm md:text-6xl 2xl:text-6xl my-5 lg:my-0">{unitData[index].name}</h1>
                                            <div key={"container-" + index} className="tabs__text-box font-normal">

                                                <ul key={"ul1-" + index} className="">
                                                    {unitData[index].features.map((item, index) => (
                                                        <>
                                                            {index < mid ? <li key={index}>{item}</li> : ""}
                                                        </>
                                                    ))}
                                                </ul>
                                                <ul key={"ul2-" + index} className="">
                                                    {unitData[index].features.map((item, index) => (
                                                        <>
                                                            {index >= mid ? <li key={index}>{item}</li> : ""}
                                                        </>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div key={"container-" + index} className="tabs-div__container image ">
                                            <img src={"../../dist/img/lakes-floor" + (index + 1) + ".jpg"}
                                                 alt="Apartment A"
                                                 className="   drop-shadow-xl cursor-pointer transform hover:scale-105 hover:drop-shadow-lg transition duration-500"
                                                 id="floorImg1"
                                                 onClick={() => displayModal("../../dist/img/lakes-floor" + (index + 1) + ".jpg")}/>
                                        </div>
                                    </>
                                )
                            }
                        })}
                    </div>


                </div>
            </section>
            <InfoSection location="cartmell"/>
           
        </React.Fragment>
    );
}

export default CartmellLakes;