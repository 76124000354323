import React from "react";
import InfoSection from "../Components/InfoSection";

const AboutUs = () => {

    return (
        <React.Fragment>
            <section className="bg-cartmell-beige-darker py-5 flex justify-center relative gradient1" style={{width:'100vw', maxWidth: '100%'}}>
                <div className="video  z-10 add-shadow" style={{marginTop:"3rem", marginBottom: "3rem"}}>
            
                    <iframe id="vid" className=" video contain"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            src='https://www.youtube.com/embed/Y9HksxQlKu0?autoplay=1&loop=1&playlist=Y9HksxQlKu0'></iframe>
                </div>
            </section>

            <InfoSection location="cartmell"/>
        </React.Fragment>
    );
}
export default AboutUs;